import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import emailIcon from '../../static/images/social/email.svg';

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allFooterMenuJson {
        edges {
          node {
            weight
            url
            name
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div className='footer'>
      <div className='container container-footer'>
        <div className='container-footer-left'>
          <div className='footer-inner'>
            <div>
              <h3 className='footer-title'>{data.site.siteMetadata.title}</h3>
              <p className='footer-title-small'>
                VNG Campus, Lot Z06, Street 13, Tan Thuan Export Processing Zone
              </p>
              <p className='footer-title-small'>
                Tan Thuan Dong Ward, District 7, HCMC, Vietnam
              </p>
              <div className='footer-copyright'>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    // eslint-disable-next-line
                    d='M8 16C5.86316 16 3.85413 15.1678 2.34314 13.6569C0.832153 12.1459 0 10.1368 0 8C0 5.86316 0.832153 3.85413 2.34314 2.34314C3.85413 0.832153 5.86316 0 8 0C10.1368 0 12.1459 0.832153 13.6569 2.34314C15.1678 3.85413 16 5.86316 16 8C16 10.1368 15.1678 12.1459 13.6569 13.6569C12.1459 15.1678 10.1368 16 8 16ZM8 1.25C4.27808 1.25 1.25 4.27808 1.25 8C1.25 11.7219 4.27808 14.75 8 14.75C11.7219 14.75 14.75 11.7219 14.75 8C14.75 4.27808 11.7219 1.25 8 1.25ZM11.2925 11.2925L10.4086 10.4086C9.08044 11.7367 6.91956 11.7367 5.59143 10.4086C4.26331 9.08044 4.26331 6.91956 5.59143 5.59143C6.91956 4.26331 9.08044 4.26331 10.4086 5.59143L11.2925 4.70752C9.47693 2.89209 6.52295 2.89209 4.70752 4.70752C2.89209 6.52307 2.89209 9.47705 4.70752 11.2925C5.61523 12.2002 6.80762 12.6541 8 12.6541C9.19226 12.6541 10.3848 12.2002 11.2925 11.2925Z'
                    fill='#4D4D4D'
                  />
                </svg>
                <span className='footer-copyright-text'>VNG Corporation</span>
              </div>
            </div>
          </div>
        </div>
        <div className='container-footer-right'>
          <div className='footer-right-1'>
            <Link className='footer-right-link' to='/'>
              Home
            </Link>
            <Link className='footer-right-link' to='/contact/'>
              Contact
            </Link>
          </div>
          <div className='footer-right-2'>
            <a
              target='_blank'
              rel='noreferrer'
              className='footer-right-icon'
              href='https://twitter.com/vngsecresponse'
            >
              <svg
                className='footer-right-icon-svg'
                role='img'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <title>Twitter icon</title>
                <path
                  fill='#EB5A26'
                  // eslint-disable-next-line
                  d='M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z'
                />
              </svg>
            </a>
            <a className='footer-right-icon' href='mailto:vsrc@vng.com.vn'>
              <img className='footer-right-icon-svg' src={emailIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
